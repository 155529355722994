import { FC, useState } from "react";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";
import { serverSnippetIF } from "../../data/types/snippets";
import { useFetchSnippets } from "../../lib/snippets/useFetchSnippets";
import { getUserName } from "../../lib/signinup/handleTokens";
import { Info } from "../atoms/alert/alert";
import { SnippetDetails } from "./SnippetDetails";
import { SimplePagination } from "../atoms/Paging";
import { SortAndFilterGallery } from "./SortAndFilter/SortAndFilter";
import { GalleryItem } from "./GalleryItem";

import "./Gallery.css";

const defaultSortAndFilterParams: QueryGetSnippetsArgs = {
  limit: 500,
  sort: { field: "updatedAt", direction: "DESC" },
  filter: { public: true },
};

const GalleryItems: FC<{
  setSelectedSnippet: (s: serverSnippetIF | undefined) => void;
}> = ({ setSelectedSnippet }) => {
  const [sortAndFilterParams, setSortAndFilterParams] =
    useState<QueryGetSnippetsArgs>(defaultSortAndFilterParams);

  const { serverSnippets: snippets } = useFetchSnippets({
    sortAndFilterParams,
  });

  const username = getUserName();

  const [displaySnippetIds, setDisplaySnippetsIds] = useState([] as string[]);
  const itemsPerPage = 10;

  return (
    <div className="container gallery-container mt-2r">
      <div className="title mb-1r">Snippet Gallery</div>

      <SortAndFilterGallery
        sortAndFilterParams={sortAndFilterParams}
        setSortAndFilterParams={setSortAndFilterParams}
      />
      {snippets.length === 0 && (
        <div>
          <Info isAlert={true}>NO SNIPPET FOUND.</Info>
        </div>
      )}

      {snippets.length > 0 && (
        <>
          <div className="mb-1r">
            <SimplePagination
              snippetIds={snippets.map((s) => s.id)}
              itemsPerPage={itemsPerPage}
              setDisplaySnippets={setDisplaySnippetsIds}
            />
          </div>
          <div className="gallery-box row">
            {snippets
              .filter((s) => displaySnippetIds.includes(s.id))
              .map((snippet, i) => {
                const displayUsername =
                  snippet.owner === username ? "You" : snippet.owner;
                return (
                  <div className="col-12 col-lg-6" key={snippet.id}>
                    <GalleryItem
                      snippet={snippet}
                      setSelectedSnippet={setSelectedSnippet}
                      username={displayUsername}
                    />
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

const Gallery: FC = () => {
  const [selectedSnippet, setSelectedSnippet] = useState(
    undefined as serverSnippetIF | undefined
  );

  if (selectedSnippet) {
    return (
      <SnippetDetails
        snippet={selectedSnippet}
        setSelectedSnippet={() => {
          setSelectedSnippet(undefined);
        }}
      />
    );
  }

  return <GalleryItems setSelectedSnippet={setSelectedSnippet} />;
};

export default Gallery;
