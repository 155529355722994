import React, { FC, ReactNode, useState, useRef } from "react";
import Markdown from "react-markdown";
import { TwButtons } from "../atoms/TwButtons";
import { ReactComponent as InfoSvg } from "../../assets/imgs/info.svg";
import { ReactComponent as SortArrowDown } from "../../assets/imgs/SortArrowDown.svg";
import { useOutsideAlerter } from "../atoms/detectClickOutside";
import { ReactComponent as Close } from "../../assets/imgs/close.svg";
import { ReactComponent as Code } from "../../assets/imgs/code.svg";
import { TStyle, TPosition } from "../../lib/ui/getModalPosition";

import { useDivPosition, useChildStyles } from "./GetPositionWrapper";
import "./TailwindModal.css";
import "./OpenPopup.css";

type TModalBtn = {
  text: string;
  type: "primary" | "secondary";
};

export const ModalBox: FC<{
  title?: string;
  content?: string;
  component?: ReactNode;
  btns?: TModalBtn[];
  setIsModalOpen?: (isModalOpen: boolean) => void;
}> = ({ title, content = "", component, btns, setIsModalOpen }) => {
  return (
    <div className="modal-box">
      {title && (
        <div className="modal-header">
          <h3>{title}</h3>
          <button
            type="button"
            className="button-modal"
            title="close modal"
            onClick={() => {
              if (setIsModalOpen) {
                setIsModalOpen(false);
              }
            }}
          >
            <Close />
          </button>
        </div>
      )}
      {component}
      {content && (
        <div className="modal-content">
          <Markdown>{content}</Markdown>
        </div>
      )}
      {btns && (
        <div className="modal-footer">
          {btns.map((btn, idx) => (
            <div key={idx}>
              <TwButtons text={btn.text} type={btn.type} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const TailwindModal: FC<{
  title: string;
  content: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
}> = ({ title, content, setIsModalOpen }) => {
  const modalBoxRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter({ ref: modalBoxRef, setOpen: setIsModalOpen });

  return (
    <div className="modal-container">
      <div className="modal-container-inner">
        <div ref={modalBoxRef}>
          <ModalBox
            title={title}
            content={content}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </div>
    </div>
  );
};

const parseMarkdown = (
  markdown: string
): { header: string; content: string } => {
  const regex = /^### (.+?)\n([\s\S]*)$/m;
  const match = markdown.match(regex);

  if (match) {
    const header = match[1].trim();
    const content = match[2].trim();
    return { header, content };
  }

  return { header: "", content: "" };
};

/*
if a parentposition parameter is passed to the wrapper, the left and right position of the modal is calculated dependent on the size of the parent compopnent
*/
export type TDirection =
  | "left"
  | "right"
  | "bottom-left"
  | "center"
  | "top-right";
type TIcon = "info" | "warning" | "open" | "code";
type TSize = "small" | "medium" | "large";

/* 
the component passed as a parameter which opens when the button is clicked, must have an optional parameter setIsModalOpen  
*/

interface OpenCloseModalProps {
  title?: string;
  content?: string;
  component?: ReactNode;
  children?: React.ReactElement<{ onClick?: () => void }>;
  parentPosition?: TPosition;
  mStyle?: TStyle;
  disabled?: boolean;

  btns?: TModalBtn[];
  direction?: TDirection;
  icon?: TIcon;
  isModalOpenDefault?: boolean;
  size?: TSize;
}

interface OpenCloseWithPositioningProps extends OpenCloseModalProps {
  parentPosition?: TPosition;
}

const RenderOpenModalButton: FC<{
  children?: React.ReactElement<{ onClick?: () => void }>;
  icon?: TIcon;
  disabled?: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  isModalOpen: boolean;
}> = ({ children, icon, disabled = false, setIsModalOpen, isModalOpen }) => {
  const handleClick = () => {
    if (!disabled) setIsModalOpen(!isModalOpen);
  };

  if (children) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        onClick: handleClick,
      });
    }

    return null;
  }

  const buttonClasses = {
    warning: "orange-outline",
    info: "blue-outline",
    open: "",
    code: "gray-outline",
  };

  const buttonIcons: Record<string, ReactNode> = {
    warning: <InfoSvg />,
    info: <InfoSvg />,
    open: (
      <>
        <span>Filter</span>
        <SortArrowDown />
      </>
    ),
    code: (
      <>
        <span className="sr-only">Display code</span>
        <Code />
      </>
    ),
  };

  return (
    <button
      type="button"
      className={`button ${icon ? buttonClasses[icon] : ""}`}
      onClick={handleClick}
    >
      {buttonIcons[icon || ""]}
    </button>
  );
};

export const RenderOpenModal: FC<OpenCloseModalProps> = ({
  btns,
  children,
  component,
  content = "",
  direction = "right",
  disabled = false,
  icon,
  isModalOpenDefault = false,
  mStyle = {
    right: "0",
    left: "auto",
    width: "400px",
  },
  size,
  title,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isModalOpenDefault);
  const modalBoxRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter({ ref: modalBoxRef, setOpen: setIsModalOpen });

  const { header, content: markdownContent } = parseMarkdown(content);
  const resolvedTitle = title || header;
  const resolvedContent = markdownContent || content;

  const clonedComponent =
    component && React.isValidElement(component)
      ? React.cloneElement(
          component as React.ReactElement<{
            setIsModalOpen?: (isOpen: boolean) => void;
          }>,
          { setIsModalOpen }
        )
      : null;

  return (
    <div className={`oc-btn-and-modal-box${icon ? ` ${icon}` : ""}`}>
      <span className="oc-btn button-and-popup-container">
        <RenderOpenModalButton
          children={children}
          icon={icon}
          disabled={disabled}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      </span>
      {isModalOpen && (
        <div
          ref={modalBoxRef}
          className={`switch-and-position-box${size === "small" ? " small" : ""} ${direction}`}
          style={mStyle}
        >
          <ModalBox
            title={resolvedTitle}
            content={resolvedContent}
            component={clonedComponent}
            btns={btns}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      )}
    </div>
  );
};

export const ModalWrapperRelativToParentPosition: FC<
  OpenCloseWithPositioningProps
> = ({ parentPosition, ...props }) => {
  const { divRef, divPos } = useDivPosition();

  const mStyle = useChildStyles({
    parentPosition,
    divPos,
  });

  return (
    <div className="position-relative" ref={divRef}>
      <RenderOpenModal {...props} mStyle={mStyle} />
    </div>
  );
};
