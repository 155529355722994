import { FC } from "react";
import { useState, useEffect } from "react";
import { sortedSentences } from "../../utils/sort-sentences";
import "./AutoComplete.css";

export const AutoComplete: FC<{
  autoCompleteList: string[];
  setSearchExpression: (exp: string) => void;
}> = ({ autoCompleteList, setSearchExpression }) => {
  const [sorted, setSorted] = useState<string[]>([]);
  useEffect(() => {
    setSorted(sortedSentences(autoCompleteList));
  }, [autoCompleteList]);

  if (autoCompleteList.length === 0) {
    return null;
  }

  return (
    <div className="dropdown-auto-complete">
      <ul>
        {sorted.map((item, i) => {
          return <li key={i} onClick={() => setSearchExpression(item)}>{item}</li>;
        })}
      </ul>
    </div>
  );
};
