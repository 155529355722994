import { FC } from "react";
import { snippetIF } from "../../data/types/snippets";
import { EMetaFieldTypes } from "../../data/types/item";
import { useStateContext } from "../../data/main/state";
import { replaceSnippet } from "../../lib/reducer/snippet/actions";
import { getUserId } from "../../lib/signinup/handleTokens";
import { syncSnippet } from "../../lib/lambda/syncSnippet";
import { MainEditField } from "../forms/MainEditField";
import { ModalWrapperRelativToParentPosition } from "../modules/TailwindModal";
import { TPosition } from "../../lib/ui/getModalPosition";
import { setIsLoading } from "../../lib/reducer/settings/actions";
import Markdown from "react-markdown";
import { Tooltip } from "../atoms/tooltip";

const confirmPublishTextGoPublic = `Do you really want to publish this snippet to the **Gallery**?

If you select the **public** flag, then everyone can see your snippet in the **Gallery** and can copy it.`;

const confirmPublishTextGoPrivate = `Do you really want to remove this snippet from the **Gallery**?

If you remove the public flag from your snippet, then you still can manage its cloud version in the **Gallery**, but noone else can see it there or copy it.`;

const ConfirmPublishSnippet: FC<{
  snippet?: snippetIF;
  setIsModalOpen?: (isOpen: boolean) => void;
}> = ({ snippet, setIsModalOpen }) => {
  const {
    state: {
      settings: { user },
    },
    dispatch,
  } = useStateContext();

  const isUserSignedIn = user?.email;

  if (!snippet) return null;

  const isPublic = snippet.public;
  const userId = getUserId() as string | undefined;

  return (
    <div className="p-1r pl-15r">
      <Markdown>
        {isPublic
          ? isUserSignedIn
            ? confirmPublishTextGoPrivate
            : "Please sign in to unpublish your snippet"
          : isUserSignedIn
            ? confirmPublishTextGoPublic
            : "Please sign in to publish your snippet"}
      </Markdown>
      <div className="flex gap-1 mt-2r mb-1r">
        <button
          type="button"
          className={`button orange${isUserSignedIn ? "" : " disabled"}`}
          onClick={() => {
            const asyncFn = async () => {
              if (!userId) return;

              dispatch(setIsLoading(true));

              const newOrUpdatedSnippet = await syncSnippet({
                snippet: {
                  ...snippet,
                  public: !isPublic,
                },
                userId,
              });
            
              if (newOrUpdatedSnippet) {
                dispatch(
                  replaceSnippet({
                    newSnippet: newOrUpdatedSnippet,
                    oldSnippetId: snippet.id,
                  })
                );
              }

              dispatch(setIsLoading(false));
              setIsModalOpen && setIsModalOpen(false);
            };

            if (isUserSignedIn) {
              asyncFn();
            }
          }}
        >
          {isPublic ? "Unpublish" : "Publish"}
        </button>
        <button
          type="button"
          className="button gray-outline"
          onClick={() => {
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export const PublishUnpublishBtn: FC<{
  divPos?: TPosition;
  isDisabled?: boolean;
}> = ({ divPos, isDisabled }) => {
  const {
    state: {
      settings: { user },
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();

  const editSnippet = snippets.find((t: snippetIF) => t.id === editSnippetId);
  if (!editSnippet) return null;

  const isUserSignedIn = user?.email;
  const isPublishButtonDisabled = isDisabled || !isUserSignedIn;

  return (
    <ModalWrapperRelativToParentPosition
      title={
        isUserSignedIn
          ? editSnippet.public
            ? "Unpublish"
            : "Publish"
          : "Sign in"
      }
      component={<ConfirmPublishSnippet snippet={editSnippet} />}
      parentPosition={divPos}
      disabled={isDisabled || !isUserSignedIn}
    >
      <label
        className={`label${isPublishButtonDisabled ? " disabled" : ""} tooltip-container-parent`}
      >
        <span>public</span>
        <MainEditField
          key="public"
          fieldMeta={{
            name: "public",
            type: EMetaFieldTypes.boolean,
            onOff: true,
          }}
          valueObj={{ value: editSnippet.public }}
          isFieldDisabled={isPublishButtonDisabled}
        />
        {isPublishButtonDisabled && (
          <Tooltip
            tooltipText={`Sign in to be able to ${editSnippet.public ? "publish to " : "unpublish from "}the Gallery.`}
            direction="top-left"
          />
        )}
      </label>
    </ModalWrapperRelativToParentPosition>
  );
};
