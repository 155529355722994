import { useRef, useState, FC, useEffect } from "react";
import { RgbaColor, RgbaColorPicker } from "react-colorful";
import { rgbaToHex, hexToRgba } from "../../lib/reducer/settings/color";
import { useOutsideAlerter } from "../atoms/detectClickOutside";
import "./ColourPicker.css";

export const PopoverPicker: FC<{
  color: RgbaColor;
  setValue?: (value: RgbaColor) => void;
  isFieldDisabled?: boolean;
  presetColours?: RgbaColor[];
}> = ({ color, setValue, isFieldDisabled, presetColours }) => {
  const [currentColorPickerColor, setCurrentColorPickerColor] =
    useState<RgbaColor>(color);
  const [hexColor, setHexColor] = useState<string>(rgbaToHex(color));

  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);
  useOutsideAlerter({
    ref: popoverRef,
    setOpen: setIsOpen,
    onClose: () => {
      if (setValue) {
        setValue(currentColorPickerColor);
      }
    },
  });

  useEffect(() => {
    const rgba = hexToRgba(hexColor);
    rgba && setCurrentColorPickerColor(rgba);
  }, [hexColor]);

  if (isFieldDisabled) {
    return (
      <div
        className="swatch disabled"
        style={{ backgroundColor: hexColor }}
        onClick={() => {
          setIsOpen(true);
        }}
      ></div>
    );
  }

  return (
    <div className="picker">
      <div
        className="swatch"
        style={{ backgroundColor: hexColor }}
        onClick={() => {
          setIsOpen(true);
        }}
      ></div>
      {isOpen && (
        <div className="color-picker-popover" ref={popoverRef}>
          <div className="wrapper">
            <RgbaColorPicker
              color={currentColorPickerColor}
              onChange={(newColor: RgbaColor) => {
                setCurrentColorPickerColor(newColor);
                setHexColor(rgbaToHex(newColor));
              }}
            />

            {presetColours && presetColours.length > 0 && (
              <div className="presets mt-1r">
                {presetColours.map((p, i) => {
                  const pHex = rgbaToHex(p);
                  return (
                    <button
                      key={i}
                      type="button"
                      style={{ backgroundColor: pHex }}
                      onClick={() => {
                        setCurrentColorPickerColor(p);
                        setHexColor(rgbaToHex(p));
                      }}
                    >
                      <span className="sr-only">{pHex}</span>
                    </button>
                  );
                })}
              </div>
            )}

            {/* HEX Input */}
            <div className="hex-color">
              <label>
                <span className="sr-only">enter color hex</span>
                <input
                  type="text"
                  value={hexColor}
                  onChange={(e) => {
                    let newColor = e.target.value;
                    const regex = /^#/;
                    if (!regex.test(newColor)) {
                      newColor = "#" + newColor;
                    }
                    setHexColor(newColor);
                  }}
                />
                <small>
                  Enter 3, 6 or 8 digit HEX color
                </small>
              </label>
            </div>

            {/* RGBA Input */}
            <div className="hex-color">
              <label>
                <span className="sr-only">Enter RGBA color</span>
                <input
                  type="text"
                  value={`${currentColorPickerColor.r},${currentColorPickerColor.g},${currentColorPickerColor.b},${currentColorPickerColor.a}`}
                  onChange={(e) => {
                    const newRgba = e.target.value.split(",").map(Number);
                    if (
                      newRgba.length === 4 &&
                      newRgba.every(
                        (val, index) =>
                          !isNaN(val) &&
                          ((index < 3 && val >= 0 && val <= 255) || // r, g, b range
                            (index === 3 && val >= 0 && val <= 1)) // a range
                      )
                    ) {
                      const rgba: RgbaColor = {
                        r: newRgba[0],
                        g: newRgba[1],
                        b: newRgba[2],
                        a: newRgba[3],
                      };
                      setCurrentColorPickerColor(rgba);
                      setHexColor(rgbaToHex(rgba));
                    }
                  }}
                />
                <small>Enter RGBA color</small>
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
