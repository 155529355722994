import React, {  FC } from "react";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";

const getActiveField = (dir?: string, field?: string) => {
    if (dir === "DESC") {
      if (field === "updatedAt") {
        return "Newest";
      } else if (field === "copyCount") {
        return "Most popular";
      } else {
        return "Title";
      }
    }
  };
  

export const PopupSort: FC<{
    sortAndFilterParams: QueryGetSnippetsArgs;
    setSortAndFilterParams: React.Dispatch<
      React.SetStateAction<QueryGetSnippetsArgs>
    >;
    setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }> = ({ sortAndFilterParams, setSortAndFilterParams, setIsMenuOpen }) => {
    const dir = sortAndFilterParams.sort?.direction as string | undefined;
    const field = sortAndFilterParams.sort?.field as string | undefined;
    const isActive = getActiveField(dir, field);
  
    const sort = sortAndFilterParams.sort || { direction: "DESC" };
  
    return (
      <div className="simple-menu">
        <a
          href="#home"
          className={`menu-item ${isActive === "Newest" ? "active" : ""}`}
          onClick={() => {
            if (isActive === "Newest") return;
  
            setSortAndFilterParams({
              ...sortAndFilterParams,
              sort: {
                ...sort,
                field: "updatedAt",
              },
            });
  
            setIsMenuOpen && setIsMenuOpen(false);
          }}
        >
          Newest
        </a>
        <a
          href="#home"
          className={`menu-item ${isActive === "Most popular" ? "active" : ""}`}
          onClick={() => {
            if (isActive === "Most popular") return;
  
            setSortAndFilterParams({
              ...sortAndFilterParams,
              sort: {
                ...sort,
                field: "copyCount",
              },
            });
  
            setIsMenuOpen && setIsMenuOpen(false);
          }}
        >
          Most popular
        </a>
        <a
          href="#home"
          className={`menu-item ${isActive === "Title" ? "active" : ""}`}
          onClick={() => {
            if (isActive === "Title") return;
  
            setSortAndFilterParams({
              ...sortAndFilterParams,
              sort: {
                direction: "ASC",
                field: "name",
              },
            });
  
            setIsMenuOpen && setIsMenuOpen(false);
          }}
        >
          Title
        </a>
      </div>
    );
  };