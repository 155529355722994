import { useState, useEffect } from "react";
import { serverSnippetIF } from "../../data/types/snippets";
import { snippetIF } from "../../data/types/snippets";
import { useStateContext } from "../../data/main/state";
import { setIsLoading } from "../reducer/settings/actions";
import { getSnippetsFromServer } from "../lambda/getSnippets";
import { renderCssAndHtmlForSnippet } from "./renderCssAndHtmlForSnippet";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";
import { getUserNamesForUserIds } from "../api/getUserNamesForUserIds";

const saveSnippetsToLocal = (snippets: snippetIF[]) => {
  localStorage.setItem("serverSnippets", JSON.stringify(snippets));
};
// const getSnippetsFromLocal = (): snippetIF[] => {
//   const snippetsJson = localStorage.getItem("serverSnippets");
//   return snippetsJson ? JSON.parse(snippetsJson) : [];
// };

export const fetchSnippets = async ({
  sortAndFilterParams,
}: {
  sortAndFilterParams: QueryGetSnippetsArgs;
}): Promise<serverSnippetIF[]> => {
  const filteredAndSorted = await getSnippetsFromServer({
    limit: sortAndFilterParams.limit,
    filter: sortAndFilterParams.filter,
    sort: sortAndFilterParams.sort,
  });

  saveSnippetsToLocal(filteredAndSorted);

  const userNames = await getUserNamesForUserIds(
    filteredAndSorted.map((s) => s.owner).filter(Boolean) as string[]
  );

  const snippets = await Promise.all(
    filteredAndSorted.map(async (s) => {
      const snippetWithCssAndHtmlRendered = await renderCssAndHtmlForSnippet(s);
      const username = s.owner ? userNames[s.owner] : undefined;
      return {
        ...snippetWithCssAndHtmlRendered,
        owner: username || "NONE",
      };
    })
  );

  return snippets.filter(Boolean) as serverSnippetIF[];
};

export const useFetchSnippets = ({
  sortAndFilterParams,
}: {
  sortAndFilterParams: QueryGetSnippetsArgs;
}) => {
  const [serverSnippets, setServerSnippets] = useState<serverSnippetIF[]>([]);
  const [cssRenderComplete, setCssRenderComplete] = useState(false);

  const { dispatch } = useStateContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setIsLoading(true));

        const newSnippets = await fetchSnippets({ sortAndFilterParams });

        setServerSnippets(newSnippets);

        if (newSnippets.length > 0 && !newSnippets.some((s) => !s.snippetCss)) {
          setCssRenderComplete(true);
        }
      } catch (error) {
        console.error("Error fetching snippets:", error);
      } finally {
        dispatch(setIsLoading(false)); // Ensure loading state is reset
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAndFilterParams.sort, sortAndFilterParams.filter]);

  return { serverSnippets, cssRenderComplete };
};
