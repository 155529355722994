import {
  itemIF,
  metaIF,
  groupIF,
  EMetaFieldTypes,
} from "../../data/types/item";
import { useStateContext } from "../../data/main/state";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";
import { itemTypes } from "../../lib/meta/getMetas";
import {
  getDependendOnDisabledFields,
  getGroupsToHide,
} from "../../lib/items/getDisabledFields";
import { getItemsDataStr } from "../../lib/reducer/items/getItemData";
import { removeFieldGroup } from "../../lib/reducer/items/actions";
import { getSetValueFn } from "../../lib/form/getSetValueFn";
import { EditFieldCheckbox } from "./Checkbox";
import { ModalWrapperRelativToParentPosition } from "../modules/TailwindModal";
import { useDivPosition } from "../modules/GetPositionWrapper";
import { EditItem } from "./EditItem";

import { AddOrRemoveFieldGroupAddButton } from "./AddOrRemoveFieldGroup";
import { RenderGroupFieldsOnly } from "./RenderGroupFieldsOnly";

/*
Explanation of editSnippetIdOther:
- edit snippet id (in the app state) is the snippet selected in the general HTML, CSS, SVG Editor 
- editSnippetIdOther is the snippet selected in the Stylepack Editor
- it has to be passed to the action changing the stylepack, for the reducer to know which snippet to change
- and in the Colourpicker of the stylepack to show its own preset colours and not the one of the edit stylepack of the edit snippet in the general editor
*/

export const EditGroup = ({
  group,
  meta,
  editItem,
  itemType,
  isItemDisabled,
  addOrRemoveEditItemId,
  editSnippetIdOther,
}: {
  group: groupIF;
  meta: metaIF;
  editItem: itemIF;
  itemType: itemTypes;
  isItemDisabled?: boolean;
  addOrRemoveEditItemId?: number;
  editSnippetIdOther?: string;
}) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const esId = editSnippetIdOther || editSnippetId;
  const editSnippet = snippets.find((s) => s.id === esId);

  group.fields.forEach((fieldName) => {
    if (fieldName === "selectContentSvg") {
      return null;
    }
  });

  const dataStr = getItemsDataStr(itemType);
  const items = editSnippet?.data[dataStr] as itemIF[];

  const hiddenGroups = [] as string[];
  if (editItem) {
    getGroupsToHide({
      meta,
      item: editItem,
      items,
      hiddenGroups,
    });
  }

  const { divRef, divPos } = useDivPosition();
  if (hiddenGroups.includes(group.name)) {
    return null;
  }

  const firstFieldInGroupName = group.fields[0];
  const firstFieldMeta = meta?.fields?.find(
    (f) => f?.name === firstFieldInGroupName
  );
  if (
    !firstFieldMeta ||
    firstFieldMeta.dontEdit ||
    firstFieldMeta.type === "x"
  ) {
    return null;
  }
  const dependendOnDisabledFields = [] as string[];
  if (editItem) {
    getDependendOnDisabledFields({
      meta,
      item: editItem,
      items,
      disabledFields: dependendOnDisabledFields,
    });
  }

  let isFirstInGroupFieldDisabled = dependendOnDisabledFields.includes(
    firstFieldInGroupName
  );
  if (isFirstInGroupFieldDisabled) {
    return null;
  }

  const help = group.help
    ? group.help
    : firstFieldMeta?.help
      ? firstFieldMeta?.help
      : "";
  const warning = group.warning
    ? group.warning
    : firstFieldMeta?.warning
      ? firstFieldMeta?.warning
      : "";

  const isAddOrRemoveItem = meta.category.includes("addOrRemoveFieldGroup");
  if (!editItem) return <div>NO EDIT ITEM</div>;

  const firstFieldName = firstFieldMeta.name;

  const setValueFnParamsObj = {
    addOrRemoveEditItemId,
    editItemId: editItem.id,
    fieldGroupName: isAddOrRemoveItem ? editItem.name : undefined,
    itemType,
    valueType: "onOff",
    editSnippetIdOther,
    dispatch,
  };

  const firstFieldValue = editItem?.value?.[firstFieldInGroupName];

  /*  
    if field type is addOrRemoveFieldGroup, it's a field in the main item with dependent items in its value object:
    - render the add button
    - render the dependent items in MainEditField as .value.map(i => <EditItem addOrRemoveEditItemId={i.id}/> )
    if addOrRemoveEditItemId exists in EditItem and its meta has the category addOrRemoveFieldGroup, it's a dependent item:
      don't render the on off checkbox at the beginning of the dependent item fields group   
      render the remove button at the end of the group 
  */

  if (!editItem) return <div>NO EDIT ITEM</div>;

  const isGroupDisabled =
    isItemDisabled ||
    (firstFieldMeta && !firstFieldMeta?.onOff && !firstFieldValue?.onOff);

  return (
    <div className="edit-item-group-wrapper" ref={divRef}>
      {!isAddOrRemoveItem && (
        <div className="flex align-center flex-1">
          <label className={`label${firstFieldMeta?.onOff ? " disabled" : ""}`}>
            <span className="sr-only">enable/disable</span>
            <EditFieldCheckbox
              fieldMeta={firstFieldMeta}
              isFieldDisabled={firstFieldMeta?.onOff}
              isChecked={
                firstFieldMeta?.onOff || firstFieldValue?.onOff || false
              }
              setValue={getSetValueFn({
                ...setValueFnParamsObj,
                fieldName: firstFieldName,
                dispatch,
              })(EMetaFieldTypes.boolean, "onOff")}
            />
          </label>
        </div>
      )}

      {firstFieldMeta.type === EMetaFieldTypes.addOrRemoveFieldGroup && (
        <>
          <div className="flex align-center">
            <AddOrRemoveFieldGroupAddButton
              itemType={itemType}
              firstFieldInGroupName={firstFieldInGroupName}
              isGroupDisabled={isGroupDisabled || isItemDisabled}
              editItemId={editItem.id}
              editSnippetIdOther={editSnippetIdOther}
            />
          </div>
          <div className="w-100p">
            {editItem.value?.[firstFieldName]?.value &&
              (editItem.value?.[firstFieldName]?.value as itemIF[]).map(
                (item, index) => {
                  return (
                    <EditItem
                      key={index}
                      itemType={itemType}
                      editItem={item}
                      isItemDisabled={isGroupDisabled}
                      addOrRemoveEditItemId={editItem.id}
                      editSnippetIdOther={editSnippetIdOther}
                    />
                  );
                }
              )}
          </div>
        </>
      )}

      {firstFieldMeta.type !== EMetaFieldTypes.addOrRemoveFieldGroup &&
        !isAddOrRemoveItem && (
          <div className="flex gap-1 wrap justify-start w-100p">
            <RenderGroupFieldsOnly
              group={group}
              dependendOnDisabledFields={dependendOnDisabledFields}
              meta={meta}
              isGroupDisabled={isGroupDisabled}
              editItem={editItem}
              setValueFnParamsObj={setValueFnParamsObj}
              editSnippet={editSnippet}
            />
          </div>
        )}

      {firstFieldMeta.type !== EMetaFieldTypes.addOrRemoveFieldGroup &&
        isAddOrRemoveItem && (
          <div className="flex justify-space-between gap-2 align-center add-or-remove-field-group-wrapper w-100p">
            <div className="flex gap-1 wrap">
              <RenderGroupFieldsOnly
                group={group}
                dependendOnDisabledFields={dependendOnDisabledFields}
                meta={meta}
                isGroupDisabled={isGroupDisabled}
                editItem={editItem}
                setValueFnParamsObj={setValueFnParamsObj}
                editSnippet={editSnippet}
              />
            </div>

            <button
              type="button"
              className={`button orange icon`}
              onClick={() => {
                if (isItemDisabled) return;
                dispatch(
                  removeFieldGroup({
                    fieldName: editItem.name,
                    fieldGroupId: editItem.id,
                    itemType,
                    editItemId: addOrRemoveEditItemId,
                    editSnippetId:
                      itemType === itemTypes.STYLEPACK
                        ? editSnippetIdOther
                        : undefined,
                  })
                );
              }}
            >
              <span className="sr-only">Remove field group</span>
              <Delete />
            </button>
          </div>
        )}

      {(help || warning) && (
        <div className="flex align-center gap-1">
          {help && (
            <ModalWrapperRelativToParentPosition
              content={help}
              icon="info"
              parentPosition={divPos}
            ></ModalWrapperRelativToParentPosition>
          )}
          {warning && (
            <ModalWrapperRelativToParentPosition
              content={warning}
              icon="warning"
              parentPosition={divPos}
            ></ModalWrapperRelativToParentPosition>
          )}
        </div>
      )}
    </div>
  );
};
