import React, { useState, FC, useEffect } from "react";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";
import { getUserId } from "../../../lib/signinup/handleTokens";
import { Tooltip } from "../../atoms/tooltip";

const getCurrentFilter = (sortAndFilterParams: QueryGetSnippetsArgs) => {
  const filter = sortAndFilterParams.filter || {};

  const searchExpression =
    filter.name && filter.name.length > 0 ? filter.name[0] : "";
  const allOrMine =
    filter.public === true && filter.owner === undefined
      ? "All snippets"
      : "My snippets";

  return { searchExpression, allOrMine };
};

export const MineOrAllPublic: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSortAndFilterParams: React.Dispatch<
    React.SetStateAction<QueryGetSnippetsArgs>
  >;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ sortAndFilterParams, setSortAndFilterParams, setIsModalOpen }) => {
  const [userId, setUserId] = useState<string | undefined>(undefined);

  const currentFilter = getCurrentFilter(sortAndFilterParams);

  useEffect(() => {
    const userId = getUserId();
    setUserId(userId);
  }, []);

  const filter = sortAndFilterParams.filter || {};

  return (
    <div className="simple-menu form-elements-wrapper">
      <div className="flex gap-1">
        <button
          className={`button ${
            currentFilter.allOrMine === "All snippets" ? "blue" : "blue-outline"
          } tooltip-container-parent`}
          onClick={() => {
            setSortAndFilterParams({
              ...sortAndFilterParams,
              filter: {
                ...filter,
                public: true,
                owner: undefined,
                name: [],
              },
            });
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          All snippets
          <Tooltip tooltipText="Click to show all public snippets" direction="top-center" />
        </button>
        {userId && (
          <button
            className={`button ${
              currentFilter.allOrMine === "All snippets"
                ? "blue-outline"
                : "blue"
            } tooltip-container-parent`}
            onClick={() => {
              setSortAndFilterParams({
                ...sortAndFilterParams,
                filter: {
                  ...filter,
                  public: undefined,
                  owner: userId,
                  name: [],
                },
              });
              setIsModalOpen && setIsModalOpen(false);
            }}
          >
            My snippets
          <Tooltip tooltipText="Click to show all my public and private snippets" direction="top-center" />
          </button>
        )}
      </div>
      {currentFilter.searchExpression && (
        <div className="mt-1r">
          <small>
            With <b>{currentFilter.searchExpression}</b> in title or description
          </small>
        </div>
      )}
    </div>
  );
};
