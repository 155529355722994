import { useState, useRef, useEffect, FC } from "react";
import { useStateContext } from "../../data/main/state";
import {
  addSnippet,
  copySnippet,
  deleteSnippet,
} from "../../lib/reducer/snippet/actions";
import { ReactComponent as Add } from "../../assets/imgs/add.svg";
import { ReactComponent as Copy } from "../../assets/imgs/copy-all.svg";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";
import { Tooltip } from "../atoms/tooltip";
import { ModalWrapperRelativToParentPosition } from "../modules/TailwindModal";
import { useDivPosition } from "../modules/GetPositionWrapper";

const ConfirmSelectorDelete: FC<{
  setIsModalOpen?: (isOpen: boolean) => void;
}> = ({ setIsModalOpen }) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const editSnippet = snippets?.find((s) => s.id === editSnippetId);

  return (
    <div className="p-1r pl-15r">
      <p>{`Do you really want to delete '${editSnippet?.name}'?`}</p>
      <div className="flex gap-1 mt-2r mb-1r">
        <button
          type="button"
          className="button orange"
          onClick={() => {
            if (!editSnippet) return;
            dispatch(deleteSnippet(editSnippet.id));
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Delete
        </button>
        <button
          type="button"
          className="button gray-outline"
          onClick={() => {
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export const AddOrCopySnippet = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const [lastAction, setLastAction] = useState("");
  const wrapperRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (lastAction === "delete" && wrapperRef.current) {
      wrapperRef.current?.focus();
      setLastAction("");
    }
  }, [lastAction]);

  const editSnippet = snippets?.find((s) => s.id === editSnippetId);
  const isDeleteBtnDisabled = snippets.length < 2 || !editSnippet;
  const { divRef, divPos } = useDivPosition();

  return (
    <div className="form-elements-wrapper mt-1r" ref={divRef}>
      <div className="btn-row">
        <button
          type="button"
          className="button blue icon tooltip-container-parent"
          onClick={() => {
            dispatch(addSnippet({}));
          }}
        >
          <span className="sr-only">Add snippet</span>
          <Add />
          <Tooltip tooltipText="Create new snippet" direction="top-left" />
        </button>

        <button
          type="button"
          className={`button blue-outline icon${!editSnippet ? " disabled" : ""} tooltip-container-parent`}
          onClick={() => {
            if (!editSnippet) return;

            dispatch(copySnippet());
          }}
        >
          <span className="sr-only">Copy</span>
          <Copy />
          <Tooltip tooltipText="Clone current snippet" direction="top-center" />
        </button>

        <ModalWrapperRelativToParentPosition
          title="Delete selector"
          component={<ConfirmSelectorDelete />}
          disabled={isDeleteBtnDisabled}
          parentPosition={divPos}
        >
          <button
            type="button"
            className={`button orange icon ${isDeleteBtnDisabled ? " disabled" : ""}`}
            onClick={() => {}}
          >
            <span className="sr-only">Delete snippet</span>
            <Delete />
          </button>
        </ModalWrapperRelativToParentPosition>
      </div>
    </div>
  );
};
