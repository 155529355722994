import { useState, FC } from "react";
import "./DisplayOrHideSubs2.css";

export interface PropsIF {
  titles: string[];
  openTitlesDefault?: string[];
  disableTitles?: string[];
  isInnerTabs?: boolean;
  children: React.ReactNode[];
}

export const DisplayHideChildren2: FC<PropsIF> = ({
  openTitlesDefault,
  titles,
  disableTitles,
  isInnerTabs,
  children,
}) => {
  const [openTitles, setOpenTitle] = useState(openTitlesDefault || [titles[0]]);

  return (
    <div className="display-hide2">
      <ul className={isInnerTabs ? "tabs-with-underline" : "default-tabs"}>
        {titles.map((title, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                if (disableTitles?.includes(title)) return;
                setOpenTitle([title]);
              }}
            >
              <button
                type="button"
                className={`${openTitles.includes(title) ? " active" : ""}${disableTitles?.includes(title) ? " disabled" : ""}`}
              >
                {title}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="content">
        {titles.map(
          (title, index) => openTitles.includes(title) && children[index]
        )}
      </div>
    </div>
  );
};
