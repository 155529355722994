import React, { FC } from "react";
import "./VerticalTab.css";

type TButtonAndComponent = {
  button: React.ReactElement;
  component: React.ReactElement;
};

export const VerticalTab: FC<{
  buttonAndComponents: TButtonAndComponent[];
}> = ({ buttonAndComponents }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  return (
    <div className="flowbite-vertical-tabs">
      <div className="buttons">
        {buttonAndComponents.map((b, i) => {
          return (
            <button
              key={i}
              className={`tab-btn${activeTab === i ? " active" : ""}`}
              onClick={() => {
                if (i !== activeTab) {
                  setActiveTab(i);
                }
              }}
            >
              {b.button}
            </button>
          );
        })}
      </div>
      <div className="content">{buttonAndComponents[activeTab].component}</div>
    </div>
  );
};
