import React, { FC, useEffect, useState } from "react";
import "./SearchInput.css";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";
import { getSnippetsFromServer } from "../../../lib/lambda/getSnippets";
import { Tooltip } from "../../atoms/tooltip";
import { AutoComplete } from "../../modules/AutoComplete";

export const SearchInput: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSortAndFilterParams: React.Dispatch<
    React.SetStateAction<QueryGetSnippetsArgs>
  >;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ sortAndFilterParams, setSortAndFilterParams, setIsModalOpen }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchTitles, setSearchTitles] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (searchValue.length < 3) {
        setSearchTitles([]);
        return;
      }
      const filteredAndSorted = await getSnippetsFromServer({
        limit: sortAndFilterParams.limit,
        filter: { ...sortAndFilterParams.filter, name: [searchValue] },
        sort: sortAndFilterParams.sort,
      });
      const titles = filteredAndSorted
        .map((s) => s.name)
        .filter((n) => n.includes(searchValue));
      setSearchTitles(titles);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div>
      <label className="tailwind-search-input-main">
        <span className="sr-only">Search</span>
        <div className="position-relative">
          <div className="search-icon">
            <svg
              width="24px"
              height="24px"
              viewBox="0 -960 960 960"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
            </svg>
          </div>
          <input
            type="search"
            placeholder="Search snippets"
            className="input-search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <button
            type="button"
            className="button-search tooltip-container-parent" disabled={searchValue.length < 3}
            onClick={() => {
              const filter = sortAndFilterParams.filter || {};              
              setSortAndFilterParams({
                ...sortAndFilterParams,
                filter: {
                  ...filter,
                  name: [searchValue],
                },
              });

              setIsModalOpen && setIsModalOpen(false);
            }}
          >
            Search
          <Tooltip tooltipText="Click to show all snippets with the expression in the title or description" direction="top-center" />
          </button>
        </div>
      </label>

      <AutoComplete
        autoCompleteList={searchTitles}
        setSearchExpression={setSearchValue}
      />
    </div>
  );
};
