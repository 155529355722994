import { useEffect, useRef, useState } from "react";
import {
  TPosition,
  TStyle,
  getModalPosition,
} from "../../lib/ui/getModalPosition";

export const useDivPosition = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [divPos, setDivPos] = useState<TPosition | undefined>(undefined);

  useEffect(() => {
    if (divRef.current) {
      const divRect = divRef.current.getBoundingClientRect();
      setDivPos({
        x: divRect.x,
        y: divRect.y,
        width: divRect.width,
        height: divRect.height,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current]);

  return { divRef, divPos };
};

export const useChildStyles = ({
  parentPosition,
  divPos,
}: {
  parentPosition?: TPosition;
  divPos?: TPosition;
}) => {
  const [mStyle, setMStyle] = useState<TStyle | undefined>(undefined);

  useEffect(() => {
    if (parentPosition && divPos) {
      const mStyle = getModalPosition({ pPos: parentPosition, mPos: divPos });
      setMStyle(mStyle);
    }
  }, [parentPosition, divPos]);

  return mStyle;
};
