import { TObject, itemIF, metaFieldIF, EMetaFieldTypes } from "../../data/types/item";
import { getMeta, itemTypes } from "../../lib/meta/getMetas";
import { getValueStr } from "../../lib/reducer/items/getItemData";
import { getItemValuesAsObj } from "../../lib/render/getItemValuesAsObject";

const getKeyframeId = ({
  fieldMeta,
  item,
  selectors,
}: {
  fieldMeta: metaFieldIF;
  item: itemIF;
  selectors: itemIF[] | undefined;
}) => {
  if (fieldMeta.name === "keyframeId") {
    if (item.value?.keyframeId.value === 0) {
      return "none";
    } else {
      const keyFrameSelector = selectors?.find(
        (s) => s.id === item.value?.keyframeId.value
      );
      if (keyFrameSelector?.value?.main?.value) {
        return keyFrameSelector.value.main.value;
      }
    }
  }
};

export const getAddOrRemoveFieldGroup = ({
  itemType,
  fieldMeta,
  item,
  items,
  attr,
}: {
  itemType: itemTypes;
  fieldMeta: metaFieldIF;
  item: itemIF;
  items: itemIF[] | undefined;
  attr: TObject;
}) => {
  if (
    item.value &&
    item.value[fieldMeta.name] &&
    item.value[fieldMeta.name].value.length > 0
  ) {
    const addOrRemoveItems: itemIF[] =
      item.value && item.value[fieldMeta.name]
        ? item.value[fieldMeta.name].value
        : [];

    item.value[fieldMeta.name].value.forEach((fgItem: itemIF) => {
      // render addOrRemove item

      const fgMeta = getMeta(itemType, fgItem.name);
      if (fgItem.value) {
        const fgAttr: TObject = {};

        if (!fgMeta || !fgMeta.fields || !items) {
          return;
        }

        getItemValuesAsObj({
          itemType,
          item: fgItem,
          items: addOrRemoveItems,
          attr: fgAttr,
        });

        // if key is the same: the one value us overwriting the other?
        Object.keys(fgAttr).forEach((key) => {
          if (!attr[key]) {
            attr[key] = fgAttr[key];
          } else {
            attr[key] += " " + fgAttr[key];
          }
        });
      }
    });
  }
  return true;
};

export const getFieldValueStr = ({
  fieldMeta,
  item,
  items,
}: {
  fieldMeta: metaFieldIF;
  item: itemIF;
  items: itemIF[] | undefined;
}): string | undefined => {
  const useValueAsKeyOfField = fieldMeta?.useValueAsKeyOfField;
  if (useValueAsKeyOfField) {
    const itemValue = item?.value?.[useValueAsKeyOfField]?.value;
    if (!itemValue) return;
    if (useValueAsKeyOfField) {
      return itemValue;
    }
  }

  const keyframeId = getKeyframeId({ fieldMeta, item, selectors: items });
  if (keyframeId) {
    return keyframeId;
  }

  if (!item?.value || !item?.value[fieldMeta.name]) {
    return;
  }

  let valObj;
  if (
    fieldMeta.canBeAuto ||
    fieldMeta.canBeCalculated ||
    fieldMeta.canBeInherited ||
    fieldMeta.canBeInitial ||
    fieldMeta.canBeAddOptions
  ) {
    /*
    if canBe field and 
      meta field type === "select" -> can be fields are added to options
      meta field type !== "select" -> add 'Select' + fieldMeta.name select field  
    * */

    const fieldName =
      fieldMeta.type === EMetaFieldTypes.select
       || fieldMeta.type === EMetaFieldTypes.dynamicSelect ? fieldMeta.name : `${fieldMeta.name}Select`;

    const key = item?.value?.[fieldName]?.value;

    if (key === undefined) {
      console.error("item has canBe fields but no main select", item);
      console.log(fieldMeta);
      return;
    }

    if (key === "calculate") {
      const fieldName = `${fieldMeta.name}Calculate`;
      valObj = {
        ...item.value[fieldName],
        value: `calc(${item.value[fieldName].value})`,
      };
    } else if (
      key === "auto" ||
      key === "initial" ||
      key === "inherit" ||
      fieldMeta.canBeAddOptions?.includes(key)
    ) {
      valObj = { value: key };
    } else {
      valObj = item.value[fieldMeta.name];
    }
  } else {
    valObj = item.value[fieldMeta.name];
  }

  if (!valObj) return;

  let valueStr = fieldMeta.wrapperFn
    ? fieldMeta.wrapperFn(valObj, items)
    : getValueStr({
        valObj,
      });


  if (valueStr || valueStr === "" || valueStr === "0") {
    return valueStr;
  }
};
