export const sortedSentences = (sentences: string[]):string[] => {
  return sentences.sort((a, b) => {
    const wordsA = a.split(" ");
    const wordsB = b.split(" ");

    // Compare each word in order
    for (let i = 0; i < Math.max(wordsA.length, wordsB.length); i++) {
      const wordA = wordsA[i] || ""; // Use empty string if no word at this position
      const wordB = wordsB[i] || ""; // Use empty string if no word at this position

      const comparison = wordA.localeCompare(wordB);
      if (comparison !== 0) {
        return comparison; // Return if words are different
      }
    }

    return 0; // All words are identical, maintain order
  });
};
